import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Person from '@mui/icons-material/Person';
import MenuBook from '@mui/icons-material/MenuBook';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Routes, Link as RouterLink, NavLink as RouterNavLink} from 'react-router-dom';




function ResponsiveAppBar({user}) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  





  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };



return (
<AppBar position="static" style={{ background:"#186545"}} >
      <Container maxWidth="xl">
        <Toolbar disableGutters> 
          <Box sx={{ flexGrow: 0,  }}  >
            <Tooltip title="Avaa navigointipalkki">
              <IconButton onClick={handleOpenMenu} sx={{ p: 0 }}>
                <MenuIcon sx={{ color:'white', borderRadius:1}}  />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{style:{boxShadow:'none', paddingTop:0, paddingBottom:0},}}
              open={Boolean(anchorElNav)}
              onClose={handleCloseMenu}
            >
              
                <MenuItem onClick={handleCloseMenu} style={{background:"#186545"}}>
                  <Typography textAlign="center">
                    <RouterNavLink to ='/' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}><span className='text-black nav-link'>ETUSIVU</span></RouterNavLink>
                             
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu} style={{background:"#186545"}}>
                  <Typography textAlign="center">
                    <RouterNavLink to ='/media' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}><span className='text-black nav-link'>MEDIALLE</span></RouterNavLink>
                             
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu} style={{background:"#186545"}}>
                  <Typography textAlign="center">
                    <RouterNavLink to ='/yhteydenotto' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}><span className='text-black nav-link'>OTA YHTEYTTÄ</span></RouterNavLink>
                             
                  </Typography>
                </MenuItem>

              
            </Menu>
          </Box>


        </Toolbar>
      </Container>
    </AppBar>

    );
}
export default ResponsiveAppBar;