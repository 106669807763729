import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Etusivu from './pages/etusivu';
import Medialle from './pages/medialle';
import Yhteydenotto from './pages/yhteydenotto';
import Bigscreenmenu from './components/Bigscreenmenu';
import Navbar from './components/navbar';

function App() {

  const [mediaMatches, setMediaMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  )
  const location = useLocation();

  useEffect(() => {
    if(location.pathname !== "/"){
      if(location.pathname === "/media"){
        document.title="Medialle | LingOra®"
      }
      else{
        document.title="Ota yhteyttä | LingOra®"
      }
    }
    else{
      document.title="LingOra®"
    }

  }, [location]);

  
  useEffect(() => {
    window
    .matchMedia("(max-width: 1200px)")
    .addEventListener('change', e => setMediaMatches( e.matches ));
  }, []);

  useEffect(() => {

  }, [mediaMatches]);

  

  return (
    <div className="App">

        {mediaMatches && (<Navbar  />)}
        {!mediaMatches && (<Bigscreenmenu location={location} />)}
        <Routes>
          <Route path="/" element={<Etusivu />} />
          <Route path="/media" element={<Medialle/>} />
          <Route path="/yhteydenotto" element={<Yhteydenotto/>}/>
        </Routes>
      
    </div>
  );
}

export default App;
