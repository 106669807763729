import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton  from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'; 
import RotateIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Editoitu_150_Ml_Pullokuva from '../images/editoitu-150-ml-pullokuva.jpg';




function Editoitu150MlPullokuvaDialog({setEditoitu150MlPullokuvaDialogOpen}) {

    const [open, setOpen] = useState(true);
    const [imageRotationTimes, setImageRotationTimes] = useState(0);
    const [imageScale, setImageScale] = useState(null);

    
    
      const handleClose = () => {
        setOpen(false);
        setEditoitu150MlPullokuvaDialogOpen(false);
         
      }



      const rotateImage = (event) => {


        const editoituPullokuva = document.getElementById("editoitu-150-ml-pullokuva");
        setImageRotationTimes(imageRotationTimes+1)
      }
      
      const zoomImage = (event) => {


        
        let scale = 1;
        scale += event.deltaY * -0.001;
        

        
        
        
        scale = Math.min(Math.max(.65, scale), 1.05);
        
        
        
        if(imageScale !== 0){
          if(scale*imageScale <= 1){
            if(scale*imageScale >= 0.5){
              
              setImageScale(scale*imageScale)
            }
            else{
              
              setImageScale(0.5);
            }
          }
          else{
              
              setImageScale(1);
          }
        }
        
        else{
          setImageScale(scale)
        }
        
      
        
        
        
      }

    useEffect(()=>{
      /*This is needed because imageScale can't be set to 1 at start without triggering the effect hook
      -> no DOM image element found in time*/
      if(imageScale===null){
        setImageScale(1);
      }
      else{   
        
        const editoituPullokuva = document.getElementById("editoitu-150-ml-pullokuva");
        editoituPullokuva.style.transform="scale("+imageScale+") rotate("+90*imageRotationTimes+"deg)";

      }
    }, [imageScale])

    useEffect(()=>{
      if(imageRotationTimes){
      const editoituPullokuva = document.getElementById("editoitu-150-ml-pullokuva");
      editoituPullokuva.style.transform="scale("+imageScale+") rotate("+90*imageRotationTimes+"deg)"
      }
    },[imageRotationTimes])


    
    
    
    
    return(
        <>

            <Dialog open={open} PaperProps={{style:{ backgroundColor: 'transparent', boxShadow: 'none'},}}> 
            <IconButton color="default" sx={{color:"white"}} onClick={() => {
                            handleClose(true)
                        }
                    }>
                    <CloseIcon/>     
                </IconButton>

                <IconButton color="default" sx={{color:"white"}} onClick={rotateImage}>
                    <RotateIcon/> 
                </IconButton>
            <DialogContent>

            
            
            <img id="editoitu-150-ml-pullokuva" src={Editoitu_150_Ml_Pullokuva} alt="editoitu-150-ml-pullokuva" 
              
              onWheel={
                zoomImage    
            } width="auto" height="auto"/>
           


            </DialogContent>
             <Typography backgroundColor="black" color="white" textAlign="center"><b>LingOra®</b></Typography>

            </Dialog>
        </>
    )

}

export default Editoitu150MlPullokuvaDialog;