import React from 'react';





function Yhteydenotto(props) {

  
  
  
  

  

    return(
      
        <div>
          

            
            <main className='container p-0'><div className='pagecontent'>
              
              <h1>Ota yhteyttä</h1>
              <p>
                Pirjo Pärnänen<br />
                Helläntie 36<br />
                01420 Vantaa<br />
                p. <a href="tel:040%205432566"><strong>040 5432566</strong></a><br />
                <a href="mailto:lingora@outlook.com"><strong>lingora@outlook.com</strong></a><br />
                
              </p>
              <p>
              Onko sinulla kysyttävää tuotteesta? <br/>
              Voit ottaa yhteyttä sähköpostilla.
              </p>
  
                      
 
        </div>
        </main>
        </div>
      );
    }
    
    export default Yhteydenotto;

