import React, { useState } from 'react';
import Pullokuva400x400Dialog from '../components/Pullokuva400x400Dialog';
import ml_pullokuva_pullokuva_400_x_400 from '../images/150-ml-pullokuva.400x400.jpg';


function Medialle(props) {

    const [Pullokuva400x400DialogOpen, setPullokuva400x400DialogOpen] = useState(false);

    return(
        <div>
            {Pullokuva400x400DialogOpen ? <Pullokuva400x400Dialog setPullokuva400x400DialogOpen={setPullokuva400x400DialogOpen}/> : <></>}


            
            <main className='container p-0'>

            
                <div className='pagecontent'>
                    
                    <h1>Medialle</h1><p>Lingora-pichaus Alfa-tv:n InnoSuomi- ohjelman jaksossa 6/8.<br />
                    Voit katsoa ohjelman osoitteessa <a href="https://kansalaismedia.com" target="_blank" rel="noreferrer noopener"><strong>kansalaismedia.com</strong></a>
                    </p>
                    <p>Klikkaa kuvat suuremmiksi</p>
                    
                    <div className='row gallery'>
                        <input className='col-image r12mb' type="image" src={ml_pullokuva_pullokuva_400_x_400} alt="LingOra®" onClick={()=>setPullokuva400x400DialogOpen(true)}/> 
                    </div>   
                </div>
            </main>
        </div>
      );
    }
    
export default Medialle;