import React, { useState } from 'react';
import Editoitu150MlPullokuvaDialog from '../components/Editoitu150MlPullokuvaDialog';
import editoitu_150_ml_pullokuva from '../images/editoitu-150-ml-pullokuva.jpg';


function Etusivu(props) {

    const [Editoitu150MlPullokuvaDialogOpen, setEditoitu150MlPullokuvaDialogOpen] = useState(false);

    
    return(
        <>
            {Editoitu150MlPullokuvaDialogOpen ? <Editoitu150MlPullokuvaDialog setEditoitu150MlPullokuvaDialogOpen={setEditoitu150MlPullokuvaDialogOpen}/> : <></>}
            


            <main className='container p-0'>
                <div className='row rr m-0 pagecontent'>
                    <div className='col-image r7 gallery mbr'>
                        
                        <input className='block' type="image" src={editoitu_150_ml_pullokuva} alt="LingOra®" onClick={()=>setEditoitu150MlPullokuvaDialogOpen(true)}/>
                    </div>
                    <div className='col-pagemaintext r7'>
                        

                        <h1>LingOra®</h1>
                        
                        <p><strong>Fermentoitu puolukkatäysmehutiiviste</strong></p>

                        <p>Hyvät tilaajat,</p>

                        <p>Puolukkasuuhuuhdetta voi ostaa: </p>
                        
                           <p><a href="https://hyvinvoinnin.fi" target="_blank" rel="noreferrer noopener"><strong>hyvinvoinnin.fi</strong></a>
                           <br/><a href="https://karkkainen.com/verkkokauppa/" target="_blank" rel="noreferrer noopener"><strong>karkkainen.com/verkkokauppa</strong></a>
                           <br/><a href="https://sinunapteekki.fi" target="_blank" rel="noreferrer noopener"><strong>sinunapteekki.fi</strong></a>
                           <br/><a href="https://pur-kauppa.fi" target="_blank" rel="noreferrer noopener"><strong>pur-kauppa.fi</strong></a>
                           <br/><a href="https://ruohonjuuri.fi" target="_blank" rel="noreferrer noopener"><strong>ruohonjuuri.fi</strong></a>
                           <br/><a href="https://biomed.fi" target="_blank" rel="noreferrer noopener"><strong>Biomed.fi</strong></a>
                           <br/><a href="https://fiksuapteekki.fi" target="_blank" rel="noreferrer noopener"><strong>fiksuapteekki.fi</strong></a></p>
                        

                        <p>Ystävällisin terveisin,</p>

                        <p>Pirjo Pärnänen<br />
                    
                        Lingora</p>
                    </div>
                </div>
        </main>
        </>
      );
    }
    
    export default Etusivu;