import React, {useState} from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import '../App.css';

function Bigscreenmenu({location}) {

    
    const [activeBigScreenMenuLink, setActiveBigScreenMenuLink]=useState('');

    const handleBigScreenMenuLinkClick = (event) => {
        
        
    }

    return(
        <header>
                <div className='container p-0'>
                    <div className='row'>
                        <div className='col-menu r2'></div>
                        <nav id='menu' className='col-menu r2 up'>
                        <ul>
                            <RouterNavLink to ='/' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')} onClick={handleBigScreenMenuLinkClick}><span className='text-black nav-link'>Etusivu</span></RouterNavLink>
                            <RouterNavLink to ='/media' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')} onClick={handleBigScreenMenuLinkClick}><span className='text-black nav-link'>Medialle</span></RouterNavLink>
                            <RouterNavLink to ='/yhteydenotto' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')} onClick={handleBigScreenMenuLinkClick}><span className='text-black nav-link'>Ota yhteyttä</span></RouterNavLink>
                        </ul>
                        
                        </nav>
                    </div>
                    <button id='menu-toggler'></button>
                    </div>
            </header>
    )
}

export default Bigscreenmenu;