import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton  from '@mui/material/IconButton'; 
import CloseIcon from '@mui/icons-material/Close';
import RotateIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Pullokuva_400_x_400 from '../images/150-ml-pullokuva.400x400.jpg';





function Pullokuva400x400Dialog({setPullokuva400x400DialogOpen}) {

    const [open, setOpen] = useState(true);
    const [imageRotationTimes, setImageRotationTimes] = useState(0);
    const [imageScale, setImageScale] = useState(null);
    
      const handleClose = () => {
        setOpen(false);
        setPullokuva400x400DialogOpen(false);
        
        
      }

      const rotateImage = (event) => {

        const Pullokuva400 = document.getElementById("150-ml-pullokuva.400x400");
        setImageRotationTimes(imageRotationTimes+1)
      }

      const zoomImage = (event) => {

      
        
        let scale = 1;
        scale += event.deltaY * -0.001;
        
        
        
        
        scale = Math.min(Math.max(.65, scale), 1.05);
        

        if(imageScale !== 0){
          if(scale*imageScale <= 1){
            if(scale*imageScale >= 0.5){
              setImageScale(scale*imageScale)
            }
            else{    
              setImageScale(0.5);
            }
          }
          else{  
              setImageScale(1);
          }
        }
        
        else{
          setImageScale(scale)
        }
        
      
      }


      useEffect(()=>{
        /*This is needed because imageScale can't be set to 1 at start without triggering the effect hook
        -> no DOM image element found in time*/
        if(imageScale===null){
          setImageScale(1);
        }
        else{   
          const editoituPullokuva = document.getElementById("150-ml-pullokuva.400x400");
          editoituPullokuva.style.transform="scale("+imageScale+") rotate("+90*imageRotationTimes+"deg)";
        }
      }, [imageScale])

      useEffect(()=>{
        if(imageRotationTimes){
        const Pullokuva400 = document.getElementById("150-ml-pullokuva.400x400");
        Pullokuva400.style.transform="scale("+imageScale+") rotate("+90*imageRotationTimes+"deg)"
        }
      },[imageRotationTimes])


    return (
        <>
            <Dialog open={open} PaperProps={{style:{ backgroundColor: 'transparent', boxShadow: 'none'},}}> 
            <DialogContent>
                <img id="150-ml-pullokuva.400x400" src={Pullokuva_400_x_400} alt="150-ml-pullokuva.400x400"               
                    onWheel={
                    zoomImage}  
                />
                
            </DialogContent>   
            <Typography backgroundColor="black" color="white" textAlign="center"><b>LingOra®</b></Typography>
                <IconButton color="default" sx={{color:"white"}} onClick={() => {
                            handleClose(true)
                        }
                    }>
                    <CloseIcon/>     
                </IconButton>

                <IconButton color="default" sx={{color:"white"}} onClick={rotateImage}>
                    <RotateIcon/> 
                </IconButton>
            </Dialog>
        </>
    )

}

export default Pullokuva400x400Dialog;